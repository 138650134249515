@import "antd.css";

:root {
  --primary-color: #322e71;
  --table-header-bg: #158d94;
  --table-header-color: #ffffff;
  --table-odd-row-bg: #d4efe8;
  --table-even-row-bg: #ffffff;
  --phone-input-height: 47px;
  --phone-input-font-size: 16px;
  --react-select-height: 53px;
  --sidebar-icon-bg-color: "#2b2b2b";
  --sidebar-title-color: "#adadad";
  --sidebar-title-hover-color: "#ffffff";
  --sidebar-title-active-color: "#ffffff";
  --label-bg-color: "#fff";
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
html,
body {
  height: 100%;
}

/* These styles disable body scrolling if you are using <ScrollView> */
body {
  overflow: hidden;
}

/* These styles make the root element full-height */
#root {
  display: flex;
  height: 100%;
}

.sbContentHolder-thumb-vertical {
  z-index: 2;
  cursor: pointer;
  border-radius: inherit;
  background-color: rgba(0, 0, 0, 0.3);
}

.PhoneInputInput {
  height: var(--phone-input-height) !important;
  margin-right: 5px;
  font-size: var(--phone-input-font-size);
  font-weight: medium;
  border: none;
  outline: none;
}

.select__control {
  border: none !important;
  outline: none !important;
  min-height: var(--react-select-height) !important;
  margin-right: 2px;
}

.select__control--is-focused {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.rc-table table {
  border-collapse: collapse;
}
.rc-table-thead {
  border-top: 1px solid #d5d5d5;
  border-bottom: 1px solid #d5d5d5;
}
.rc-table-thead .rc-table-cell {
  background-color: var(--table-header-bg);
  color: var(--table-header-color) !important;
}

.rc-table-tbody .rc-table-row {
  background-color: var(--table-odd-row-bg);
}

.rc-table-tbody .rc-table-row:nth-of-type(even) {
  background-color: var(--table-even-row-bg);
}

.rc-table-tbody .rc-table-row:last-child {
  border-bottom: 1px solid #d5d5d5;
}

.rc-table-thead .rc-table-cell {
  border-right: 1px solid #d5d5d5;
}

.rc-table-thead .rc-table-cell:last-child {
  border-right: 0px !important;
}

.rc-table-tbody .rc-table-cell {
  border-right: 1px solid #d5d5d5;
}

.rc-table-tbody .rc-table-cell:last-child {
  border-right: 0px !important;
}

.rc-table-cell {
  padding-left: 20px;
  padding-right: 20px;
  height: 40px;
}

.rc-pagination-item-active {
  border-color: var(--primary-color) !important;
}

.rc-pagination-item-active a {
  color: var(--primary-color) !important;
}

.Dropdown-control {
  width: 65px !important;
}

.pro-icon-wrapper {
  background-color: var(--sidebar-icon-bg-color) !important;
}

.pro-menu-item {
  color: var(--sidebar-title-color);
}

.pro-menu-item:hover {
  color: var(--sidebar-title-hover-color) !important;
}

.pro-menu-item.active {
  color: var(--sidebar-title-active-color) !important;
}

.demo-app {
  display: flex;
  min-height: 100%;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
}

.demo-app-main {
  flex-grow: 1;
}

.fc {
  max-width: 1100px;
  margin: 0 auto;
}

.jodit-container:not(.jodit_inline) {
  border: none !important;
  border-bottom-right-radius: 6px;
}

.jodit-placeholder {
  display: none !important;
}

.DayPickerInput-Overlay {
  z-index: 10001 !important;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}
#numberBox {
  border: none;
  outline: none;
  overflow: hidden;
}

#numberBox::-webkit-inner-spin-button,
#numberBox::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

#numberBox:hover {
  background-color: var(--label-bg-color) !important;
  border: none;
  outline: none;
}

#numberBox:focus {
  border-color: transparent !important;
  border: none !important;
  outline: none !important;
}
#textBox {
  border: none;
  outline: none;
  overflow: hidden;
}

#textBox:hover {
  background-color: var(--label-bg-color) !important;
  border: none !important;
  outline: none !important;
}

#textBox:focus {
  border-color: transparent !important;
  border: none !important;
  outline: none !important;
}

#textarea {
  border: none;
  outline: none;
  resize: none;
  padding-top: 10px;
}

#textarea:hover {
  background-color: var(--label-bg-color) !important;
  border: none !important;
  outline: none !important;
}

#textarea:focus {
  border-color: transparent !important;
  border: none !important;
  outline: none !important;
}

.header-fixed {
  background-color: #292c2f;
  box-shadow: 0 1px 1px #ccc;
  padding: 20px 40px;
  height: 80px;
  color: #ffffff;
  box-sizing: border-box;
  top: -100px;

  -webkit-transition: top 0.3s;
  transition: top 0.3s;
}

.header-fixed .header-limiter {
  max-width: 1200px;
  text-align: center;
  margin: 0 auto;
}

/*	The header placeholder. It is displayed when the header is fixed to the top of the
	browser window, in order to prevent the content of the page from jumping up. */

.header-fixed-placeholder {
  height: 80px;
  display: none;
}

/* Logo */

.header-fixed .header-limiter h1 {
  float: left;
  font: normal 28px Cookie, Arial, Helvetica, sans-serif;
  line-height: 40px;
  margin: 0;
}

.header-fixed .header-limiter h1 span {
  color: #5383d3;
}

/* The navigation links */

.header-fixed .header-limiter a {
  color: #ffffff;
  text-decoration: none;
}

.header-fixed .header-limiter nav {
  font: 16px Arial, Helvetica, sans-serif;
  line-height: 40px;
  float: right;
}

.header-fixed .header-limiter nav a {
  display: inline-block;
  padding: 0 5px;
  text-decoration: none;
  color: #ffffff;
  opacity: 0.9;
}

.header-fixed .header-limiter nav a:hover {
  opacity: 1;
}

.header-fixed .header-limiter nav a.selected {
  color: #608bd2;
  pointer-events: none;
  opacity: 1;
}

/* Fixed version of the header */

body.fixed .header-fixed {
  padding: 10px 40px;
  height: 50px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

body.fixed .header-fixed-placeholder {
  display: block;
}

body.fixed .header-fixed .header-limiter h1 {
  font-size: 24px;
  line-height: 30px;
}

body.fixed .header-fixed .header-limiter nav {
  line-height: 28px;
  font-size: 13px;
}

/* Making the header responsive */

@media all and (max-width: 600px) {
  .header-fixed {
    padding: 20px 0;
    height: 75px;
  }

  .header-fixed .header-limiter h1 {
    float: none;
    margin: -8px 0 10px;
    text-align: center;
    font-size: 24px;
    line-height: 1;
  }

  .header-fixed .header-limiter nav {
    line-height: 1;
    float: none;
  }

  .header-fixed .header-limiter nav a {
    font-size: 13px;
  }

  body.fixed .header-fixed {
    display: none;
  }
}
.Loki {
  max-width: 100%;
  margin: 0 auto;
}

.LokiSteps {
  width: 100%;
  position: relative;
  display: table;
  table-layout: fixed;
  text-align: center;
  vertical-align: middle;
  border-collapse: separate;
  margin-left: 0px;
  padding-left: 0;
  padding-top: 20px;
}

.Loki-Actions {
  display: block;
  padding: 20px 0;
}

.LokiSteps .LokiStep {
  display: table-cell;
  position: relative;
  float: none;
  padding: 0;
  width: 1%;
}

.LokiSteps .LokiStep .LokiStep-Icon {
  background-color: white;
  border-radius: 50%;
  border: 1px solid #ccc;
  color: #ccc;
  font-size: 24px;
  height: 64px;
  margin: 0 auto;
  position: relative;
  width: 64px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.LokiSteps .LokiStep .LokiStep-Link.disabled {
  cursor: not-allowed;
}

.LokiSteps .LokiStep.LokiStep-Active .LokiStep-Icon {
  border-color: #75b2f5;
  color: #75b2f5;
}

.LokiSteps .LokiStep.LokiStep-Complete .LokiStep-Icon {
  border-color: #05ae0e;
  color: #05ae0e;
}

.LokiSteps .LokiStep:after {
  left: 50%;
  background-color: #ccc;
  content: "";
  display: block;
  height: 1px;
  position: absolute;
  width: 100%;
  top: 35px;
}

.LokiSteps .LokiStep:last-child:after {
  display: none;
}
.ant-input-number,
.ant-picker {
  width: 100% !important;
}
.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #134475;
}

.logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}
::-webkit-scrollbar {
  width: 8px;
}
@media all and (max-width: 600px) {
  ::-webkit-scrollbar {
    width: 3px;
  }
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* .ant-tabs-tab {




  font-family: Roboto-Medium;

} */
/* 
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,

.ant-tabs-tab:hover {

  color: #134475 !important;

}

.ant-tabs-ink-bar {

  background: #134475 !important;

}
.ant-select-selector:hover{
  border-color: #134475 !important;

}
.ant-select-open{
  box-shadow:none;


}
.ant-select-focused{
  border-color: #134475 !important;
}
.ant-select, .ant-select-in-form-item, .ant-select-status-success, .ant-select-single, .ant-select-allow-clear, .ant-select-show-arrow, .ant-select-show-search{
  box-shadow:none
} */
/*
	 We are clearing the body's margin and padding, so that the header fits properly.
	 We are also adding a height to demonstrate the scrolling behavior. You can remove
	 these styles.
 */
.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: #134475 !important;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #134475 !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: #134475 !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: #134475;
  box-shadow: 0 0 0 2px rgba(122, 29, 159, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-status-error:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input:focus,
.ant-input-status-error:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input-focused {
  border-color: #134475;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  border-right-width: 1px;
  outline: 0;
}

.ant-drawer-title,
.ant-drawer-close {
  color: #ffffffd1 !important;
}
.ant-form-item-label > label {
  color: rgba(0, 0, 0, 0.85) !important;
}

/* .anticon .anticon-close{ */
/* position: absolute !important;
color: #5383d3 !important;
right:2;
top: 2; */
/* } */
/* .ant-drawer-header-title{
  flex: 1 !important;
  color: #75b2f5 !important;
  position:absolute !important;
  align-items: center !important;
} */

/* .ant-btn .ant-btn-primary .ant-btn-dangerous{
  background-color: red !important;
} */

.form-card {
  background: #3c3c3c !important;
  border: none !important;
}

.form-card .ant-card-head {
  border-bottom: 1px solid #646464 !important;
}

.form-card .ant-card-head-title {
  color: white !important;
}

.form-card .ant-empty-description {
  color: white !important;
}

.form-card .ant-empty-img-default-path-5 {
  display: none !important;
}

.form-card .ant-empty-img-default-g {
  display: none !important;
}

.sbEntities-thumb-vertical {
  z-index: 2;
  cursor: pointer;
  border-radius: inherit;
  background-color: #d7d7d74d;
}

#sbEntities > div:last-child {
  opacity: 0;
  transition: opacity 200ms ease;
}

#sbEntities:hover > div:last-child {
  opacity: 1;
}

.access_rights_form label {
  color: black !important;
}

.ant-form-item-label > label:after {
  content: "" !important;
}

.access_rights_form .ant-form-item-label {
  width: 100px;
  text-align: left !important;
}

.access_rights_form .ant-form-item-control {
  flex: 1;
}

.horizontal_container {
  display: flex;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.horizontal_container::-webkit-scrollbar {
  display: none;
}

.ant-form .ant-form-vertical .user_form {
  color: #111;
}
.user_form label {
  color: #134475 !important;
}
.checkout-tabs {
  margin-left: 3rem;
  margin-right: 3rem;
}
.ant-form-horizontal .ant-form-item-label {
  width: 45% !important;
  text-align: left;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: #134475 !important;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #134475 !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: #134475 !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: #134475;
  box-shadow: 0 0 0 2px rgba(122, 29, 159, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-status-error:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input:focus,
.ant-input-status-error:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input-focused {
  border-color: #134475;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  border-right-width: 1px;
  outline: 0;
}

.ant-drawer-title {
  color: #fff;
}
.ant-drawer-close {
  color: #fff;
}

.date-pos {
  position: absolute;
  top: 0;
  right: 10px;
  font-size: 14px !important;
}
.ant-btn .ant-btn-primary {
  background-color: #134475 !important;
}
/* .file-upload .ant-upload-list-picture-card-container {
  display: inline-block;
  width: 104px;
  height: 104px;
  margin: 0 8px 8px 0;
  vertical-align: top;
} */

.ant-table-thead .ant-table-cell {
  background-color: var(--table-header-bg);

  color: var(--table-header-color) !important;
}

.ant-table-tbody .ant-table-row {
  background-color: var(--table-odd-row-bg);
}

.ant-table-tbody .ant-table-row:nth-of-type(even) {
  background-color: var(--table-even-row-bg);
}

.ant-table-tbody .ant-table-row:last-child {
  border-bottom: 1px solid #d5d5d5;
}

.ant-table-thead .ant-table-cell {
  border-right: 1px solid #d5d5d5;
}

.ant-table-thead .ant-table-cell:last-child {
  border-right: 0px !important;
}

.ant-table-tbody .ant-table-cell {
  border-right: 1px solid #d5d5d5;
}

.ant-table-tbody .ant-table-cell:last-child {
  border-right: 0px !important;
}

.ant-table-tbody .ant-table-row .ant-table-cell-row-hover {
  background-color: #f0f0f0 !important;
}

.ant-table-tbody > tr > td {
  word-wrap: break-word;
  word-break: break-all;
}
.ant-table-cell {
  padding-left: 20px;

  padding-right: 20px;

  height: 40px;
}

table {
  border-collapse: collapse;

  width: 100%;
}

td {
  border: 1px solid #dddddd;

  text-align: left;

  padding: 8px;

  color: #111;
}

th {
  border: 1px solid #dddddd;

  text-align: center;

  padding: 8px;

  color: #ffffff;
}

thead {
  background-color: #158d94;
}

tbody tr:nth-child(odd) {
  background-color: #d4efe8;
}

.ql-container {
  min-height: 10rem;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.ql-editor {
  min-height: 10rem;
  flex: 1;
  overflow-y: auto;
  width: 100%;
}

.dashboard-table {
  margin-top: 20px !important;
  width: 90% !important;
  padding: 10px !important;
}

/* From Uiverse.io by andrew-demchenk0 */
.apk_button {
  --main-focus: #2d8cf0;
  --font-color: #dedede;
  --bg-color-sub: #222;
  --bg-color: #323232;
  --main-color: #dedede;
  position: relative;
  width: 180px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 2px solid var(--main-color);
  box-shadow: 4px 4px var(--main-color);
  background-color: var(--bg-color);
  border-radius: 10px;
  overflow: hidden;
}

.apk_button,
.apk_button__icon,
.apk_button__text {
  transition: all 0.3s;
}

.apk_button .apk_button__text {
  transform: translateX(21px);
  color: var(--font-color);
  font-weight: 600;
}

.apk_button .apk_button__icon {
  position: absolute;
  transform: translateX(130px);
  height: 100%;
  width: 39px;
  background-color: var(--bg-color-sub);
  display: flex;
  align-items: center;
  justify-content: center;
}

.apk_button .svg {
  width: 20px;
  fill: var(--main-color);
}

.apk_button:hover {
  background: var(--bg-color);
}

.apk_button:hover .apk_button__text {
  color: transparent;
}

.apk_button:hover .apk_button__icon {
  width: 148px;
  transform: translateX(0);
}

.apk_button:active {
  transform: translate(3px, 3px);
  box-shadow: 0px 0px var(--main-color);
}
